import React from "react"
import loadable from "@loadable/component"

import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import WorkHeader from "../../components/templates/work/WorkHeader"
import WorkSummaryBlock from "../../components/templates/work/WorkSummaryBlock"
// import WorkChallengeBlock from "../../components/templates/work/WorkChallengeBlock"
const WorkChallengeBlock = loadable(() =>
  import("../../components/templates/work/WorkChallengeBlock")
)
const WorkResultsBlock = loadable(() =>
  import("../../components/templates/work/WorkResultsBlock")
)
const ContactForm = loadable(() =>
  import("../../components/portfolioContactForm")
)
// import WorkResultsBlock from "../../components/templates/work/WorkResultsBlock"
// import ContactForm from "../../components/portfolioContactForm"

export default function AndreattaWaterscapes() {
  return (
    <Layout>
      <Seo
        title="Construction Company Web Design | Case Study"
        description="Andreatta Waterscapes wanted their new website to be modern, trustworthy, and personable. See how we increased sales with Web Design & SEO."
        noTitlePrefix={true}
        image={`https://snappywebdesign.net/images/andreattawaterscapes_caseStudy2.jpg`}
      />
      <WorkHeader
        company="Andreatta Waterscapes"
        title="Custom Web Design and Digital Marketing for a Landscaping Contractor"
      />
      <WorkSummaryBlock
        company="Andreatta Waterscapes"
        industries={["Landscape Architecture", "Water Features"]}
        services={[
          "Custom Web Design",
          "Website Hosting",
          "Landscaping SEO",
          "Landscape Web Design",
          "Email Marketing Integration",
          "Construction Company Web Design",
          "Custom Content Management System",
        ]}
        summaryText={[
          `Andreatta Waterscapes is the leading landscape architect in Southern Oregon. They have over 20 years of experience transforming backyards into tranquil sanctuaries with waterfalls, ponds, and hardscapes.`,
          `Their reputation is for friendly service & expert design, placing every rock with intention and attention to detail. However, their existing website didn’t convey the same level of care & wealth of expertise they have. Their website was slow, difficult to use on mobile devices, didn’t list the services they offered, and offered no way of updating content, which turned away prospective clients and limited their growth.`,
          `Their website and message needed to reflect their personalized service & superior abilities, so the goal was to build/create a website that was mobile-friendly, easy to navigate, inspiring, and had a modern but personable design. After launching their new website, we focused on increasing visibility and growth through ongoing SEO services.`,
        ]}
      />
      <WorkChallengeBlock
        challengeText={[
          `Andreatta Waterscapes is the leading expert in Oregon for water feature construction, but their website didn’t reflect that. It looked and felt outdated, didn’t work on mobile devices, and only showcased five of their over fifteen service offerings. They had no method of editing the site or uploading content to showcase their stunning work.`,
          `The main pain points of the existing site were: slow-loading pages, not being mobile-responsive, not offering a content management system, and having an outdated, clunky design.`,
          `These issues were hurting their SEO rankings and causing clients who did visit their website to leave without scheduling appointments.`,
        ]}
      />
      <WorkResultsBlock
        resultsText={`Using Gatsby.js, we created a stunning design and website management tool to solve their greatest need: a mobile-friendly design that attracts and informs new clients. The new website is well organized, easy to read, high converting, and blazing fast. The change resulted in a noticeable increase in traffic to the Andreatta Waterscapes website.`}
        resultsParagraphs={[
          {
            id: 0,
            img: (
              <StaticImage
                src="../../assets/images/aw_customWebDesign.jpg"
                alt="Andreatta Waterscapes Custom Web Design"
                imgStyle={{
                  borderRadius: 10,
                }}
              />
            ),
            title: `Custom Website Design`,
            text: `When we sat down with the folks at Andreatta Waterscapes, we collected a detailed understanding of who their customers are and who they are. After fully understanding their business and differentiators, we mapped out a design plan to 1) convey their personality, 2) display their work, 3) increase search engine rankings, and, 4) boost customer acquisition.`,
          },
          {
            id: 1,
            img: (
              <StaticImage
                src="../../assets/images/aw_localaudience.png"
                height={560}
                alt="Andreatta Waterscapes Local Audience"
                imgStyle={{
                  borderRadius: 10,
                }}
              />
            ),
            title: `Reaching a Local Audience`,
            text: `Andreatta Waterscapes is the top landscape architect in Southern Oregon, but their website was obscured on Google by unrelated businesses. We made SEO a top priority and deployed a thorough competitor analysis and keyword research process. We continue to optimize the site with high-value keywords, providing a substantial boost in organic traffic that continues to rise to this day.`,
          },
          {
            id: 2,
            img: (
              <StaticImage
                src="../../assets/images/aw_every_device.jpg"
                height={560}
                alt="Andreatta Waterscapes Mobile Responsiveness"
                imgStyle={{
                  borderRadius: 10,
                }}
              />
            ),
            title: `Stunning on Every Device`,
            text: `While their existing website was difficult and frustrating to use on a cell phone, we know that mobile functionality is a priority for any modern website. We created a design that naturally shrinks and expands to ensure a clean, attractive aesthetic no matter which device is accessing the site.`,
          },
          {
            id: 3,
            img: (
              <StaticImage
                src="../../assets/images/aw_pagespeed.jpg"
                height={560}
                alt="Andreatta Waterscapes Page Speed"
                imgStyle={{
                  borderRadius: 10,
                }}
              />
            ),
            title: `Website Speed Optimization`,
            text: `Andreatta Waterscapes’ existing website took an average of 7.5 seconds to load, increasing the probability of user’s leaving or ‘bouncing’ by over 106% according to research by Google/SOASTA. We specialize in developing blazing fast websites and decreased page-load times to an average of 1.0 seconds, as measured by Google’s PageSpeed Insights. In harnessing multiple optimization strategies, the new website feels as if it loads instantaneously.`,
          },
          {
            id: 4,
            img: (
              <StaticImage
                src="../../assets/images/aw_contentful.jpg"
                height={560}
                alt="Andreatta Waterscapes Content Management System"
                imgStyle={{
                  borderRadius: 10,
                }}
              />
            ),
            title: `Primed for Growth`,
            text: `With no method of updating or adding content to their existing site, we knew that a content management system would be a top priority. We built a custom CMS to enable the AW team to update content, upload images, and even create new pages on the fly. `,
          },
          {
            id: 5,
            img: (
              <StaticImage
                src="../../assets/images/aw_reimaged_leads.jpg"
                height={560}
                alt="Andreatta Waterscapes Lead Generation"
                imgStyle={{
                  borderRadius: 10,
                }}
              />
            ),
            title: `Lead Generation Re-Imagined`,
            text: `In addition to the usual contact form that users expect, we decided to up the lead-generation efforts by a notch. We strategically placed newsletter registration forms that give users access to a downloadable how-to guide, turning site visitors into valuable leads. We aligned user intent and behavioral patterns in our design process to ensure users are presented with the most profitable behavioral funnel.`,
          },
        ]}
      />
      <ContactForm />
    </Layout>
  )
}
