import React from "react"

import { makeStyles, useTheme } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"
const dark = "#1c8460"
const useStyles = makeStyles((theme) => ({
  rowCtr: {
    marginTop: "2rem",
    marginBottom: "3rem",
  },
  paper: {
    width: "100%",
    backgroundColor: "rgba(0,0,0,.1)",
    padding: "2rem",
    borderRadius: 0,
  },
  cardCompanyTitle: {
    fontSize: "1.33rem",
    fontWeight: 500,
  },
  cardSubSectionText: {
    fontSize: "1.2rem",
    fontWeight: 500,
  },
  cardAccentText: {
    fontSize: ".95rem",
    color: dark,
    fontWeight: 400,
  },
  hr: {
    width: "10%",
    padding: ".05rem",
    backgroundColor: dark,
    marginTop: ".25rem",
    marginBottom: "1.25rem",
    [theme.breakpoints.up("md")]: {
      marginLeft: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      width: "15%",
      marginRight: "auto",
      marginLeft: undefined,
    },
  },
  summaryTitle: {
    fontSize: "2.25rem",
    fontWeight: 500,
    marginBottom: "1.5rem",
  },
  summaryBodyText: {
    marginBottom: "1.5rem",
  },
}))

export default function WorkSummaryBlock({
  company,
  industries,
  services,
  summaryText,
}) {
  const theme = useTheme()
  const classes = useStyles()
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <>
      <Container
        maxWidth="lg"
        className={classes.rowCtr}
        style={{ padding: 20 }}
      >
        <Grid
          container
          direction={matchesSM ? "column-reverse" : undefined}
          // direction={matchesSM ? "row-reverse" : undefined}
          spacing={matchesSM ? undefined : 6}
        >
          <Grid item md={4} xs={12}>
            <Grid container justify="center">
              <Paper className={classes.paper} elevation={0}>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    className={classes.cardCompanyTitle}
                    align={matchesSM ? "left" : "right"}
                  >
                    {company}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.hr} />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    className={classes.cardSubSectionText}
                    align={matchesSM ? "left" : "right"}
                  >
                    Industries:
                  </Typography>
                </Grid>
                {industries.map((industry) => {
                  return (
                    <Grid item xs={12} key={`snappy${industry}`}>
                      <Typography
                        variant="subtitle1"
                        align={matchesSM ? "left" : "right"}
                        className={classes.cardAccentText}
                      >
                        {industry}
                      </Typography>
                    </Grid>
                  )
                })}
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    className={classes.cardSubSectionText}
                    align={matchesSM ? "left" : "right"}
                    style={{ marginTop: "1rem" }}
                  >
                    Services:
                  </Typography>
                </Grid>
                {services.map((service) => {
                  return (
                    <Grid item xs={12} key={`snappy${service}`}>
                      <Typography
                        variant="subtitle1"
                        align={matchesSM ? "left" : "right"}
                        className={classes.cardAccentText}
                      >
                        {service}
                      </Typography>
                    </Grid>
                  )
                })}
              </Paper>
            </Grid>
          </Grid>
          <Grid item md={8} xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h2"
                  className={classes.summaryTitle}
                  gutterBottom
                >
                  Summary
                </Typography>
              </Grid>
              {summaryText.map((text) => {
                return (
                  <Grid item xs={12} key={`snappy${text}`}>
                    <Typography
                      variant="body1"
                      className={classes.summaryBodyText}
                      gutterBottom
                    >
                      {text}
                    </Typography>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
