import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"

import { makeStyles, useTheme } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import headerImg from "../../../assets/images/Waterfall_Andreatta_Waterscapes_Grants_Pass_Oregon.jpg"
const dark = "#1c8460"
const Background = styled.div`
  background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(${headerImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 50% 70%;
  color: white;
  /* padding: 2rem 0; */
  text-align: center;
  @media only screen and (max-width: 1000px) {
    background-position: 50% 20%;
  }
  @media only screen and (max-width: 600px) {
    backgroundposition: 50% 20%;
    background-attachment: scroll;
  }
`
const useStyles = makeStyles((theme) => ({
  headerTitle: {
    fontSize: "2.5rem",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.25rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.75rem",
    },
  },
  headerSubtitle: {
    fontSize: "1.5rem",
    fontWeight: 300,
    paddingTop: "5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.15rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  hr: {
    width: "5%",
    padding: ".05rem",
    backgroundColor: "#fff",
    marginBottom: "1.25rem",
    [theme.breakpoints.down("sm")]: {
      width: "15%",
    },
  },
  headerBtn: {
    marginBottom: "5rem",
    fontFamily: 500,
    fontSize: "1rem",
    textTransform: "uppercase",
    padding: ".5rem 1.5rem",
    transition: ".4s",
    color: "#fff",
    backgroundColor: dark,
    "&:hover": {
      backgroundColor: dark,
      color: "#FFF",
      "& $arrowIco": {
        transform: "translate(.5em)",
      },
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: ".9rem",
    },
  },
  arrowIco: {
    marginLeft: 5,
    transition: "0.4s",
  },
  hFix: {
    "&:hover > a": {
      color: "#fff",
    },
  },
}))

export default function WorkHeader({ company, title }) {
  const classes = useStyles()
  const theme = useTheme()
  const matchesXL = useMediaQuery(theme.breakpoints.down("xl"))
  return (
    <>
      <Background>
        <Container maxWidth={matchesXL ? "lg" : "md"}>
          <Grid container>
            <Grid item sm={9} lg={10}>
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.headerSubtitle}
                paragraph
              >
                {company}
              </Typography>
              <Typography
                variant="h1"
                align="left"
                className={classes.headerTitle}
                gutterBottom
              >
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.hr} />
            </Grid>
            <Grid
              item
              xs={12}
              container
              justify="flex-start"
              className={classes.hFix}
            >
              <Button
                variant="contained"
                className={classes.headerBtn}
                component={Link}
                to="/contact/"
              >
                Let's work together{" "}
                <ArrowForwardIosIcon
                  fontSize="small"
                  className={classes.arrowIco}
                />
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Background>
    </>
  )
}

WorkHeader.propTypes = {
  company: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
WorkHeader.defaultProps = {
  company: "Andreatta Waterscapes",
  title: "Custom Web Design & Digital Marketing for a Landscaping Contractor",
}
